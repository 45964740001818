import store from '../../store/store'

const useAppFeatureAccess = () => {
  const appHasFeature = (featureCode) => {
    let result = true
    if (featureCode) {
      const appFeatures = store.getters['activeAppFeatures']
      let featureFound = appFeatures && appFeatures.find(x => x === featureCode)
      return featureFound ? true : false
    }
    return result;
  }
  return { appHasFeature }
}

export default useAppFeatureAccess;